.gh-widget-spinner > * {
  animation: gh-spinner-rotate 1.4s linear infinite;
}

@keyframes gh-spinner-rotate {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(270deg);
  }
}

.gh-widget-spinner > * > * {
  stroke-dasharray: 88px;
  stroke-dashoffset: 0;
  transform-origin: center;
  animation: gh-spinner-dash 1.4s ease-in-out infinite;
  stroke-width: 1;
  stroke-linecap: round;
}

@keyframes gh-spinner-dash {
  0% {
    stroke-dashoffset: 88px;
  }
  50% {
    stroke-dashoffset: 22px;
    transform: rotate(135deg);
  }
  100% {
    stroke-dashoffset: 88px;
    transform: rotate(450deg);
  }
}

.gh-widget-spinner-exit-active {
  opacity: 0;
  transition: opacity 0.4s;
}

.gh-widget-spinner-enter-active {
  opacity: 1;
  transition: opacity 0.4s;
}
