.gh-widget-app {
  .gh-widget-date-time-picker-inline-date-wrapper {
    flex: 2;
  }

  .gh-widget-date-time-picker-inline-time-wrapper {
    flex: 1;

    .gh-widget-old-input {
      height: 52px;
    }
  }

  .gh-widget-date-time-picker-inline-labels-wrapper {
    display: flex;
  }

  .gh-widget-date-time-picker-inline-label:first-of-type {
    flex: 2;
  }

  .gh-widget-date-time-picker-inline-label:last-of-type {
    flex: 1;
  }
}

/*===========Container Query===========*/
.gh-widget-app-cq.gh-widget-container-query-max-900 {
  .gh-widget-min-width-departure_at,
  .gh-widget-min-width-return_at {
    min-width: 110px;
  }

  .gh-widget-calendar-input {
    padding-left: 20px;
  }

  .gh-widget-date-time-picker-inline-label:last-of-type {
    transform: translateX(5px);
  }
}

.gh-widget-app-cq.gh-widget-container-query-max-375 {
  .gh-widget-calendar-input {
    padding-left: 32px;
  }
}

.gh-widget-app-cq.gh-widget-container-query-max-375 {
  .gh-widget-date-time-picker-inline-time-wrapper .rdtOpen .rdtPicker {
    min-width: 100px;
    right: 0;
  }

  .gh-widget-app-date-time-picker-error:not(.gh-widget-app-disabled-error) {
    min-height: 35px;
  }
}
