//autocomplete classes
.gh-widget-autocomplete {
  /*===========container===========*/

  &-wrapper {
    position: relative;
  }

  &-input.gh-widget-old-input {
    font-weight: 400;
  }

  &-input-wrapper {
    position: relative;
    display: flex;
  }

  &-input.gh-widget-old-input {
    border: 2px solid var(--gh-widget-border-color);
    border-right: none;
    border-radius: var(--gh-widget-border-radius) 0 0 var(--gh-widget-border-radius);
    margin-right: -1px;
  }

  &-input-full-width.gh-widget-old-input {
    border-radius: var(--gh-widget-border-radius);
    border: 2px solid var(--gh-widget-border-color);
  }

  &-loader-secondary-text-wrapper {
    width: 45%;
    display: none;
    border: 2px solid var(--gh-widget-border-color);
    border-radius: 0 var(--gh-widget-border-radius) var(--gh-widget-border-radius) 0 !important;
    border-left: 0;
    align-items: center;
    background-color: #fff;
  }

  &-input-additional-info {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    pointer-events: none;
    font-size: var(--gh-widget-font-size-normal);
    line-height: 14px;
    color: var(--gh-widget-secondary-font-color);
    margin-right: 5px;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }

  &-dropdown {
    position: absolute;
    width: 100%;
    border-radius: var(--gh-widget-border-radius);
    border: 2px solid var(--gh-widget-border-color);
    background-color: #fff;
    z-index: 1000;
  }

  &-list {
    margin: 0;
    padding: 0;
    list-style: none;
    color: var(--gh-widget-dark-font-color);
  }

  &-list-title {
    margin: 0;
    padding: 12px 18px;
    background: #f2f4f7;
    font-size: var(--gh-widget-font-size-smaller);
    text-transform: uppercase;
    color: var(--gh-widget-dark-font-color);
    font-weight: normal;
  }

  &-list-item {
    display: flex;
    justify-content: space-between;
    padding: 16px 20px;
    cursor: pointer;

    &:not(&:last-child) {
      border-bottom: 1px solid var(--gh-widget-border-color);
    }
  }

  &-list-item-active {
    background-color: #dfe2e8;
  }

  &-list-title-and-icon {
    display: flex;
    justify-content: space-between;
  }

  &-place-icon,
  &-place-title,
  &-place-location {
    display: flex;
    align-items: center;
    text-align: left;
  }

  &-place-title {
    //color: #242f42;
    min-width: 150px;
    margin-right: 5px;
    font-size: var(--gh-widget-font-size-normal);
  }

  &-place-location {
    font-size: var(--gh-widget-font-size-normal);
    color: var(--gh-widget-secondary-font-color);
  }

  /*===========Spinner===========*/
  &-spinner {
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translateY(-12px);
    background: #fff;
    padding-left: 10px;
  }
}

.gh-widget-autocomplete-animation-enter-active {
  animation: animation-show-top-to-bottom 0.3s forwards;
}

.gh-widget-autocomplete-animation-exit-active {
  animation: animation-hide-bottom-to-top 0.3s forwards;
}

/*===========Animations===========*/
@keyframes animation-hide-bottom-to-top {
  from {
    opacity: 1;
    transform: translateY(0px);
  }
  to {
    opacity: 0;
    transform: translateY(15px);
  }
}

@keyframes animation-show-top-to-bottom {
  0% {
    transform: translateY(15px);
    opacity: 0;
  }
  80% {
    opacity: 1;
  }
  100% {
    transform: translateY(0px);
  }
}

// Read-ony auto-complete
.gh-widget-app {
  .gh-widget-autocomplete-fake-input-wrapper {
    border: 2px solid var(--gh-widget-border-color);
    border-radius: var(--gh-widget-border-radius);
    background: #fff;
    color: var(--gh-widget-secondary-font-color);
    height: 48px;
    max-width: 100%;
    width: 100%;
    display: flex;
    align-items: center;
  }

  .gh-widget-autocomplete-fake-input {
    width: 100%;
    padding: 0 10px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
  }

  .gh-widget-autocomplete-readonly-secondary-text-wrapper {
    width: 45%;
  }

  .gh-widget-autocomplete-readonly {
    cursor: not-allowed;

    & > * {
      filter: opacity(0.6);
    }
  }
}

/*===========Container Query===========*/
.gh-widget-app-cq.gh-widget-container-query-max-900 {
  .gh-widget-autocomplete-list-item {
    flex-direction: column;
    justify-content: initial;
  }

  .gh-widget-autocomplete-list-title-and-icon {
    margin-bottom: 5px;
    justify-content: initial;
  }

  .gh-widget-autocomplete-place-location {
    margin-left: 31px;
  }

}
