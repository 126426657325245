$sizes: 0, 5, 10, 15, 20, 25, 45;
$sides: "top", "bottom", "right", "left";
$thicknesses: 300, 400, 500, 600, 700;
.gh-widget-app {
  /*===========Margin===========*/
  @each $size in $sizes {
    @each $side in $sides {
      .gh-widget-margin-#{$side}-#{$size} {
        margin-#{$side}: #{$size}px;
      }
    }
  }

  /*===========Padding===========*/
  @each $size in $sizes {
    .gh-widget-padding-#{$size} {
      padding: #{$size}px;
    }
    @each $side in $sides {
      .gh-widget-padding-#{$side}-#{$size} {
        padding-#{$side}: #{$size}px;
      }
    }
  }

  /*===========Positions===========*/
  @each $side in $sides {
    .gh-widget-#{$side}-0 {
      #{$side}: 0;
    }
  }

  /*===========Font Size===========*/
  $fontSizes: 10, 13, 14, 16;
  @each $size in $fontSizes {
    .gh-widget-font-size-#{$size} {
      font-size: #{$size}px;
    }
  }

  /*===========Font Weight===========*/
  @each $thickness in $thicknesses {
    .gh-widget-font-weight-#{$thickness} {
      font-weight: #{$thickness};
    }
  }

  .gh-widget-position-relative {
    position: relative;
  }

  .gh-widget-position-absolute {
    position: absolute;
  }

  /*===========Text===========*/
  /*label*/
  .gh-widget-label {
    display: block;
    font-size: var(--gh-widget-font-size-small);
    font-weight: 500;
    color: var(--gh-widget-font-color);
    padding: 0;
    margin: 0 0 10px;
    text-transform: uppercase;
  }

  /*sizes*/
  .gh-widget-text-xs {
    font-size: 0.813rem; //13px
  }

  /*===========Colors===========*/
  .gh-widget-color-primary {
    color: var(--gh-widget-primary-font-color);
  }

  .gh-widget-color-secondary {
    color: var(--gh-widget-secondary-font-color);
  }

  .gh-widget-color-light {
    color: var(--gh-widget-light-font-color-light);
  }

  /*===========Input===========*/
  .gh-widget-textarea {
    height: 80px;
    color: var(--gh-widget-primary-font-color) !important;
    border-radius: var(--gh-widget-border-radius);
    width: 100%;
    padding: 4px 10px;
    vertical-align: top;
    font-weight: 400;
    font-size: var(--gh-widget-font-size-bigger);
    border: 2px solid var(--gh-widget-border-color);
    font-family: inherit;

    &,
    &:focus-visible {
      outline: none;
    }
  }

  /*===========Display===========*/
  .gh-widget-d-none {
    display: none !important;
  }

  /*===========Flex===========*/
  .gh-widget-d-flex {
    display: flex;
  }

  .gh-widget-flex-justify-center {
    justify-content: center;
  }

  .gh-widget-flex-align-center {
    align-items: center;
  }

  .gh-widget-flex-column {
    flex-direction: column;
  }

  .gh-widget-flex-row {
    flex-direction: row;
  }

  /*===========Alerts===========*/

  .gh-widget-gray-warning {
    background: #f3f4f7;
  }

  /*===========Icons===========*/
  .gh-widget-icon-svg {
    width: 20px;
    height: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  /*===========Cursors===========*/
  .gh-widget-pointer {
    cursor: pointer;
  }
}

.gh-widget-app-cq.gh-widget-container-query-max-900 {
  .gh-widget-textarea {
    height: 200px;
  }
}
