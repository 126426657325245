.gh-widget-app {
  .gh-widget-events-wrapper {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: 1300px;
    min-height: 625px;

    .gh-widget-column-layout-first-column {
      overflow-y: auto;
      max-height: 923px;
    }
  }

  .gh-widget-main-wrapper-small-paddings .gh-widget-events-wrapper {
    margin-bottom: 35px;
    margin-inline: auto;
  }

  .gh-widget-card-additional-type-value,
  .gh-widget-card-additional-type {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    line-clamp: 2;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    word-wrap: break-word;
  }

  .gh-widget-card-additional-type {
    font-size: 0.75em;
    font-weight: 400;
    margin-bottom: 2px !important;
  }

  .gh-widget-card-additional-type-value {
    font-size: 0.75em;
    font-weight: 600;
  }

  .gh-widget-card-additional-dates {
    margin: 10px 0;
    display: flex;
    font-weight: 400;
    opacity: 0.9;
  }

  .gh-widget-card {
    background-color: #fff;
    border-radius: 4px;
    overflow: hidden;
    display: flex;
    flex-direction: column;
  }

  .gh-widget-card-image-wrapper {
    min-height: 165px;
    height: 165px;
    width: 100%;
    min-width: 250px;
    position: relative;
    overflow: hidden;
  }

  .gh-widget-card-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .gh-widget-card-info {
    height: 100%;
    padding: 1em;
    flex: 1;
    background: #f2f4f7;
  }

  .gh-widget-card-additional {
    display: flex;
  }

  .gh-widget-card-additional-left,
  .gh-widget-card-additional-right {
    display: flex;
    flex-direction: column;
    width: 50%;
  }

  .gh-widget-events-grid {
    max-width: 100%;
    width: 100vw;
    max-height: 100%;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    gap: 45px 36px;
    align-self: flex-start;
  }

  .gh-widget-events-grid-loading {
    grid-template-columns: initial;
  }

  .gh-widget-events-grid-expand {
    padding-bottom: 40px;
  }

  .gh-widget-events-loader {
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 1;
  }

  .gh-widget-card-featured-tag {
    -webkit-border-radius: 6px;
    -moz-border-radius: 6px;
    border-radius: 6px;
    padding: 4px;
    display: flex;
    align-items: center;
    border: 1px solid #d6d6d5;
    background-color: #fff;
    right: 16px;
    top: 16px;

    svg {
      margin: 0;
      height: 14px;
      width: auto;
    }

    path {
      stroke: var(--gh-widget-ui-primary-color);
    }

    & .gh-widget-app-text {
      font-size: 0.875em;
      line-height: 0.875em;
    }
  }
}

/*===========Container Query===========*/
.gh-widget-app-cq.gh-widget-container-query-max-900 {
  .gh-widget-column-layout-wrapper {
    max-height: initial;
    height: initial;
    align-items: center;
  }

  .gh-widget-card-featured-tag {
    right: 10px;
    top: 10px;
    padding: 4px;
  }

  .gh-widget-events-wrapper {
    .gh-widget-column-layout-first-column {
      overflow-y: initial;
      max-height: initial;
    }
  }

  .gh-widget-events-grid {
    grid-template-columns: repeat(auto-fill, minmax(235px, 1fr));
    width: 90vw;
  }
}

.gh-widget-app-cq.gh-widget-container-query-max-540 {
  .gh-widget-events-grid {
    grid-template-columns: repeat(auto-fill, minmax(145px, 1fr));
    gap: 16px;
  }

  .gh-widget-card-image-wrapper {
    min-width: initial;
    max-height: 96px;
    min-height: 96px;
  }

  .gh-widget-card {
    border-radius: 4px 4px 8px 8px;
  }

  .gh-widget-card-info {
    padding: 0.75rem;
  }

  .gh-widget-card-additional-type,
  .gh-widget-card-additional-type-value {
    font-size: 0.688em;
  }

  .gh-widget-card-additional-dates {
    font-size: 0.563em;
    margin: 8px 0;
  }

  .gh-widget-card-additional {
    flex-direction: column;
  }

  .gh-widget-card-additional-left {
    margin-bottom: 4px;
  }

  .gh-widget-card-additional-left,
  .gh-widget-card-additional-right {
    width: 100%;
  }

  .gh-widget-column-layout-first-column {
    overflow-y: initial;
  }

  .gh-widget-card-additional-dates {
    margin: 8px 0;
  }
}

/*===========Only Safari===========*/
@media not all and (min-resolution: .001dpcm) {
  @supports (-webkit-appearance:none) and (stroke-color:transparent) {
    .gh-widget-app .gh-widget-card {
      max-height: 282px;
    }
  }
}
