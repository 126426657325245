.gh-widget-app {
  .standardTitle {
    font-size: 1.75em;
    line-height: 36px;
    margin-bottom: 25px;
    font-weight: 400;
  }

  .mainTitle {
    font-weight: 500;
    letter-spacing: 2px;
    color: var(--gh-widget-primary-font-color);
    margin-bottom: 35px;
    font-size: 1em;
  }

  .mediumTitle {
    margin-bottom: 10px;
    font-size: 1.13em;
    font-weight: 400;
    line-height: 24px;
  }

  .smallTitle {
    color: var(--gh-widget-primary-font-color);
    font-weight: 600;
    font-size: 0.87em;
    line-height: 1em;
  }

  .extra-smallTitle {
    color: var(--gh-widget-primary-font-color);
    margin-bottom: 10px;
    font-weight: 400;
    font-size: 0.75em;
    line-height: 14px;
  }
}

/*===========Container Query===========*/
.gh-widget-app-cq.gh-widget-container-query-max-900 {
  .mainTitle {
    margin-bottom: 1em;
  }

  .smallTitle {
    font-size: 0.75em;
  }
}
