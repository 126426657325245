.gh-widget-app {
  .gh-widget-app-link {
    display: block;
    color: var(--gh-widget-primary-font-color);
    text-decoration: none;
    font-size: 1em;
    transition: opacity 0.2s ease-out;
    &:hover {
      opacity: 0.6;
    }
  }
}
