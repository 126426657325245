.gh-widget-app {
  .gh-widget-tabs-new-design {
    display: flex;
    margin-bottom: 40px;
  }

  .gh-widget-tabs-new-design-tab {
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: Rubik, sans-serif;
    font-size: 1em;
    color: var(--gh-widget-primary-font-color);
    max-width: 190px;
    width: 100%;
    line-height: 1em;
    position: relative;
    background: #fff;
    border: 1px solid var(--gh-widget-ui-primary-color);
    padding: 12px;
    white-space: nowrap;

    &:not(:last-child) {
      border-right: none;
    }
  }

  .gh-widget-tabs-new-design-tab.disabled {
    background: #f7f7f7;
    color: #6d7481;
  }

  .gh-widget-tabs-new-design-tab.disabled:hover {
    cursor: not-allowed;
  }

  .gh-widget-tabs-new-design-tab:hover {
    cursor: pointer;
  }

  .gh-widget-tabs-new-design-tab.active {
    font-weight: 500;
    background: var(--gh-widget-ui-primary-color);
    color: var(--gh-widget-light-font-color);
  }

  .gh-tooltip-disabled-tab .custom-tooltip-content {
    margin-left: -15px;
    -webkit-box-sizing: content-box;
    -moz-box-sizing: content-box;
    box-sizing: content-box;
  }
}

/*===========Container Query===========*/
.gh-widget-app-cq.gh-widget-container-query-max-900 {
  .gh-widget-tabs-new-design {
    margin-bottom: 2.2em;
  }

  .gh-widget-tabs-new-design-tab {
    max-width: 8rem;
    min-width: min-content;
    padding: 12px 0;
    font-weight: 500;
  }
}
