.gh-widget-heli-card-image {
  padding: 20px 20px 0px;
  background-color: #f2f4f7;
}

.gh-widget-heli-card-image .gh-widget-heli-card-title {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.gh-widget-heli-card-content {
  padding: 20px;
  background: #f2f4f7;
}

.gh-widget-heli-card-content .gh-widget-main-button-component {
  width: 100%;
  margin-top: 15px;
}

.gh-widget-heli-card-content .gh-widget-heli-card-subtitle {
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
}

.gh-widget-heli-card .gh-widget-heli-item-label {
  color: var(--gh-widget-secondary-font-color);
  font-weight: 400;
  font-size: var(--gh-widget-font-size-big);
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.gh-widget-heli-card .gh-widget-heli-item-text {
  color: var(--gh-widget-primary-font-color);
  font-weight: 500;
  font-size: var(--gh-widget-font-size-big);
}

.gh-widget-heli-item-range {
  margin-top: 5px !important;
  margin-bottom: 5px;
  position: relative;
  width: 100%;
}

.gh-widget-heli-item-range_line {
  width: 100%;
  height: 10px;
  background-color: #d5d5d5;
  border-radius: 30px;
  margin-top: 34px;
  position: relative;
}

.gh-widget-heli-card .gh-widget-heli-item-range_item .gh-widget-heli-item-label,
.gh-widget-heli-card .gh-widget-heli-item-range_item .gh-widget-heli-item-text {
  color: var(--gh-widget-primary-font-color);
  line-height: 1;
  font-size: var(--gh-widget-font-size-normal);
}

.gh-widget-heli-item-range_item {
  position: absolute;
  top: 0px;
  display: inline-flex;
  flex-direction: column;
}

.gh-widget-heli-item-range_item.from {
  left: 0;
}

.gh-widget-heli-item-range_item.to {
  right: 0;
  text-align: right;
}

.gh-widget-heli-item-range_item.average {
  text-align: center;
}

.gh-widget-heli-item-range_marker {
  position: absolute;
  top: calc(100% - 10px);
  height: 10px;
  width: 54px;
  background: rgb(255, 255, 255);
  background: linear-gradient(
                  90deg,
                  rgba(255, 255, 255, 0) 0%,
                  var(--gh-widget-ui-primary-color) 50%,
                  rgba(255, 255, 255, 0) 100%
  );
}

.gh-widget-heli-info-wrapper {
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 10px;
}

.gh-widget-heli-card-title {
  font-size: var(--gh-widget-font-size-normal);
  font-weight: 500;
  line-height: 1;
  color: var(--gh-widget-dark-font-color);
}

.gh-widget-heli-info-item {
  min-width: 150px;
}

.gh-widget-heli-item-label,
.gh-widget-heli-item-text {
  font-size: var(--gh-widget-font-size-small);
  font-family: inherit;
  font-weight: 400;
  line-height: 1.5;
}

.gh-widget-heli-item-label.description-title {
  margin-top: 5px;
}

.gh-widget-heli-card-futther-button {
  width: 100%;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
}

.gh-widget-enquire-actions {
  display: flex;
  padding: 20px 15px 20px 10px;
}

.gh-widget-enquire-actions .gh-widget-button-default {
  color: var(--gh-widget-primary-font-color);
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  background: #e6eaee;
  border-color: #e6eaee;
  height: 50px;
}

.gh-widget-helicopters-list {
  width: 100%;
}

.gh-widget-helicopters-list-content::-webkit-scrollbar {
  width: 6px;
}

.gh-widget-helicopters-list-content::-webkit-scrollbar-thumb {
  width: 2px;
  background: #d8d8d8;
  border: solid 1px #979797;
}

.gh-widget-helicopters-list-content::-webkit-scrollbar-track {
  background: transparent;
}

/*===========Container Query===========*/
.gh-widget-app-cq.gh-widget-container-query-max-900 {
  .gh-widget-external-enquiry-form-options {
    height: initial;
  }
}

.gh-widget-app-cq.gh-widget-container-query-max-375 {
  .gh-widget-heli-card-image {
    padding: 10px 10px 0;
  }

  .gh-widget-heli-card-content {
    padding: 10px;
  }
}

