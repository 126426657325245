.gh-widget-app {

  //  filter button
  .gh-widget-events-filter-button {
    position: absolute;
    right: 0.5em;
    top: -1.8em;
    padding: 0;
    font-size: 1.2em;
  }

  .gh-widget-events-filter-button_text {
    font-weight: 400;
  }

  .gh-widget-events-filter-button--open:before {
    content: '';
    position: absolute;
    display: flex;
    left: -.5em;
    right: -.5em;
    top: -.5em;
    bottom: -.5em;
    background: var(--gh-widget-ui-primary-color);
    opacity: .2;
    border-radius: 4px;
  }

  .gh-widget-events-filter {
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    left: 0;
    //background: rgba(0, 0, 0, 0.6);
    z-index: 100;
  }

  .gh-widget-events-filter-menu {
    position: absolute;
    right: 0;
    top: 0;
    padding: 1em;
    z-index: 101;
    width: 250px;
    background: var(--gh-widget-ui-primary-color);
    filter: opacity(.97);
    color: var(--gh-widget-light-font-color);
    max-height: 100%;
    overflow-y: auto;
  }

  .gh-widget-filter-group:not(:last-child) {
    margin-bottom: 1.2em;
  }

  .gh-widget-filter-menu_title {
    font-size: 1em;
    margin-bottom: .4em;
    color: var(--gh-widget-light-font-color);
  }

  .gh-widget-filter-menu_item {
    display: flex;
    justify-content: space-between;
  }

  .gh-widget-filter-menu_label {
  }

  .gh-widget-filter-menu_checkbox {
    margin-bottom: .7em;
    font-size: 0.93em;

    .checkbox-label {
      display: flex;
      justify-content: space-between;
    }
  }

  .gh-widget-app-reset-filters {
    padding: .3em;
    font-size: .93em;
    margin: .5em auto 0;
  }
}

/*===========Container Query===========*/
.gh-widget-app-cq.gh-widget-container-query-max-540 {
  .gh-widget-events-filter-button_text {
    display: none;
  }

  .gh-widget-events-filter-button {
    top: -1.5em;
  }
}
