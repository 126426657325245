.gh-widget-app {
  .gh-widget-image-tag {
    background-color: var(--gh-widget-ui-primary-color);
    border-radius: 4px;
    padding: 0.5em;
    display: flex;
    align-items: center;
    position: absolute;
  }

  .gh-widget-image-tag-text.gh-widget-image-tag-text {
    color: var(--gh-widget-light-font-color);
    font-size: 1em;
    line-height: normal;
  }

  .gh-widget-tag-icon {
    width: 15px;
    height: 15px;


    g, rect, path {
      fill: var(--gh-widget-light-font-color);
    }
  }
}
