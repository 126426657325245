.gh-widget-app {
  .gh-widget-events-transition {
  }

  .gh-widget-pagination-bar {
    position: absolute;
    left: 50%;
    bottom: 0;
    transition: transform 0.3s ease-in-out;
  }
}

/*===========Container Query===========*/
.gh-widget-app-cq.gh-widget-container-query-max-900 {
  .gh-widget-pagination-bar {
    position: initial;
    transform: none !important;
  }

  .pagination-item.disabled {
    display: none;
  }

  .pagination-container {
    margin-top: 20px;
  }
}
