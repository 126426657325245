.gh-widget-tooltip-define {
  font-family: var(--gh-widget-font-family);

  .gh-widget-tooltip-content {
    z-index: 199999;
  }
}

.gh-widget-tooltip-content {
  color: var(--gh-widget-light-font-color);
  padding: 10px;
  //background: #242f42;
  font-size: var(--gh-widget-font-size-smaller);
  line-height: 16px;
  font-weight: 400;
  max-width: 220px;
}
