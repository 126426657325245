.gh-widget-app {
  .pagination-container {
    display: flex;
    justify-content: center;
    align-items: center;
    list-style-type: none;
    margin-top: 40px;
    color: var(--gh-widget-secondary-font-color);

    .pagination-item {
      padding: 5px 6px;
      height: 23px;
      text-align: center;
      margin: auto 4px;
      display: flex;
      box-sizing: border-box;
      align-items: center;
      line-height: 17px;
      font-size: 14px;
      border-radius: 4px;
      transition: all 0.2s ease-out;

      &.dots:hover {
        background-color: transparent;
        cursor: default;
      }

      &:hover {
        background-color: rgba(0, 0, 0, 0.04);
        cursor: pointer;
      }

      &.selected {
        background-color: #fff;
        color: var(--gh-widget-ui-primary-color);
        font-weight: 500;
      }

      .gh-widget-arrow {
        height: 15px;
        width: 15px;
        svg path {
          stroke: var(--gh-widget-secondary-font-color);
        }

        &.left {
          transform: rotate(-180deg);
        }
      }

      &.disabled {
        pointer-events: none;
        //visibility: hidden;
        transform: scale(0);
      }
    }
  }
  .gh-widget-pagination-prefix {
    font-weight: 500;
    font-size: 14px;
  }
}
