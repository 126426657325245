.gh-widget-app {
  .gh-date-picker.gh-time-picker.gh-important {
    .gh-widget-enquire-calendar .rdtPicker {
      td,
      th {
        background: inherit;
        vertical-align: inherit;
        border: 0;
        &:hover {
          background: #eee;
        }
      }
    }
    .rtd {
      display: flex;
      align-items: center;
    }
    .enquire-time-icon {
      td,
      th {
        background-color: #fff;
      }
    }
  }

  .gh-widget-enquire-calendar .rdtOpen .gh-widget-calendar-input {
    background: #242f42;
    border-radius: 0;
    color: var(--gh-widget-light-font-color);
  }

  .gh-widget-enquire-calendar .rdtOpen .gh-widget-calendar-input::placeholder {
    color: var(--gh-widget-light-font-color) !important;
  }

  .rdt {
    position: relative;
  }

  .rdtPicker {
    display: none;
    position: absolute;
    width: 100%;
    padding: 4px;
    margin-top: 1px;
    z-index: 99999 !important;
    background: #fff;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
    border: 1px solid #f9f9f9;
  }

  .rdtOpen .rdtPicker {
    display: block;
  }

  .rdtStatic .rdtPicker {
    box-shadow: none;
    position: static;
  }

  .rdtPicker .rdtTimeToggle {
    text-align: center;
  }

  .rdtPicker table {
    width: 100%;
    margin: 0;
  }

  .rdtPicker td,
  .rdtPicker th {
    text-align: center;
    height: 28px;
  }

  .rdtPicker td {
    cursor: pointer;
  }

  .rdtPicker td.rdtDay:hover,
  .rdtPicker td.rdtHour:hover,
  .rdtPicker td.rdtMinute:hover,
  .rdtPicker td.rdtSecond:hover,
  .rdtPicker .rdtTimeToggle:hover {
    background: #eeeeee;
    cursor: pointer;
  }

  .rdtPicker td.rdtOld,
  .rdtPicker td.rdtNew {
    color: #999999;
  }

  .rdtPicker td.rdtToday {
    position: relative;
  }

  .rdtPicker td.rdtToday:before {
    content: "";
    display: inline-block;
    border-left: 7px solid transparent;
    border-bottom: 7px solid #428bca;
    border-top-color: rgba(0, 0, 0, 0.2);
    position: absolute;
    bottom: 4px;
    right: 4px;
  }

  .rdtPicker td.rdtActive,
  .rdtPicker td.rdtActive:hover {
    background-color: #428bca;
    color: var(--gh-widget-light-font-color);
    text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
  }

  .rdtPicker td.rdtActive.rdtToday:before {
    border-bottom-color: #fff;
  }

  .rdtPicker td.rdtDisabled,
  .rdtPicker td.rdtDisabled:hover {
    background: none;
    color: var(--gh-widget-light-font-color);
    filter: opacity(.5);
    cursor: not-allowed;
  }

  .rdtPicker td span.rdtOld {
    color: #999999;
  }

  .rdtPicker td span.rdtDisabled,
  .rdtPicker td span.rdtDisabled:hover {
    background: none;
    color: #999999;
    cursor: not-allowed;
  }

  .rdtPicker th {
    border-bottom: 1px solid #f9f9f9;
  }

  .rdtPicker .dow {
    width: 14.2857%;
    border-bottom: none;
  }

  .rdtPicker th.rdtSwitch {
    width: 100px;
  }

  .rdtPicker th.rdtNext,
  .rdtPicker th.rdtPrev {
    font-size: 21px;
    vertical-align: top;
  }

  .rdtPrev span,
  .rdtNext span {
    display: block;
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Chrome/Safari/Opera */
    -khtml-user-select: none; /* Konqueror */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none;
  }

  .rdtPicker th.rdtDisabled,
  .rdtPicker th.rdtDisabled:hover {
    background: none;
    color: #999999;
    cursor: not-allowed;
  }

  .rdtPicker thead tr:first-child th {
    cursor: pointer;
  }

  .rdtPicker thead tr:first-child th:hover {
    background: #eeeeee;
  }

  .rdtPicker tfoot {
    border-top: 1px solid #f9f9f9;
  }

  .rdtPicker button {
    border: none;
    background: none;
    cursor: pointer;
  }

  .rdtPicker button:hover {
    background-color: #eee;
  }

  .rdtPicker thead button {
    width: 100%;
    height: 100%;
  }

  td.rdtMonth,
  td.rdtYear {
    height: 50px;
    width: 25%;
    cursor: pointer;
  }

  td.rdtMonth:hover,
  td.rdtYear:hover {
    background: #eee;
  }

  .rdtCounters {
    display: inline-block;
  }

  .rdtCounters > div {
    float: left;
  }

  .rdtCounter {
    height: 100px;
  }

  .rdtCounter {
    width: 40px;
  }

  .rdtCounterSeparator {
    line-height: 100px;
  }

  .rdtCounter .rdtBtn {
    height: 40%;
    line-height: 40px;
    cursor: pointer;
    display: block;

    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Chrome/Safari/Opera */
    -khtml-user-select: none; /* Konqueror */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none;
  }

  .rdtCounter .rdtCount {
    height: 20%;
    font-size: 1.2em;
    color: var(--gh-widget-font-color);
  }

  .rdtMilli {
    vertical-align: middle;
    padding-left: 8px;
    width: 48px;
  }

  .rdtMilli input {
    width: 100%;
    font-size: 1.2em;
    margin-top: 37px;
  }

  .gh-widget-utc {
    position: absolute;
    left: 85px;
    top: 13px;
    z-index: 4;
  }

  .gh-widget-enquire-calendar-icon {
    width: 22px;
    height: 22px;
    z-index: 2;
    position: absolute;
  }

  .gh-widget-enquire-form-content .gh-widget-enquire-calendar-icon {
    left: 17px;
    top: 15px;
  }

  .gh-widget-calendar-icon,
  .gh-widget-close-icon {
    z-index: 999;
    height: 46px;
  }

  .gh-widget-icon-label,
  .gh-widget-calendar-label {
    font-family: inherit;
    font-size: var(--gh-widget-font-size-normal);
    font-weight: 500;
    position: absolute;
    top: 13px;
    right: 50px;
    z-index: 2;
    color: var(--gh-widget-primary-font-color);
    display: block;
  }

  .gh-widget-calendar-icon,
  .gh-widget-close-icon {
    z-index: 999;
    height: 46px;
  }

  .gh-widget-close-icon {
    left: auto;
    right: 0;
    cursor: pointer;
    pointer-events: auto !important;
  }

  .gh-widget-enquire-calendar .rdtPicker {
    background: #6d7481;
    color: var(--gh-widget-light-font-color);
    border: solid 1px var(--gh-widget-border-color);
    border-radius: var(--gh-widget-border-radius);
  }

  .gh-widget-enquire-calendar .rdtPicker th {
    color: var(--gh-widget-light-font-color) !important;
    border-bottom: 0;
  }

  .gh-widget-enquire-calendar .rdtPicker th:hover,
  .gh-widget-enquire-calendar .rdtPicker td:hover {
    color: var(--gh-widget-primary-font-color) !important;
  }

  .gh-widget-enquire-calendar .rdtPicker .rdtPrev,
  .gh-widget-enquire-calendar .rdtPicker .rdtNext {
    display: flex;
    align-items: flex-end;
    justify-content: center;
  }

  .gh-widget-enquire-calendar .rdtPicker td.rdtActive {
    background: #ffffff;
    color: var(--gh-widget-primary-font-color);
  }

  .gh-widget-enquire-calendar .rdtPicker td.rdtActive:hover {
    background: #242f42;
    color: var(--gh-widget-light-font-color);
  }

  .gh-widget-enquire-calendar .rdtOpen .rdtPicker {
    width: 100%;
    margin-top: 5px;
  }

  .gh-widget-enquire-calendar .rdtPicker {
    color: var(--gh-widget-light-font-color);
    border: solid 1px var(--gh-widget-border-color);
    border-radius: var(--gh-widget-border-radius);
  }

  .gh-widget-enquire-calendar .rdtPicker th:hover,
  .gh-widget-enquire-calendar .rdtPicker td:hover {
    color: var(--gh-widget-primary-font-color) !important;
  }

  .gh-widget-enquire-calendar .rdtPicker .rdtPrev,
  .gh-widget-enquire-calendar .rdtPicker .rdtNext {
    display: flex;
    align-items: flex-end;
    justify-content: center;
  }

  .gh-widget-enquire-calendar .rdtPicker td.rdtActive {
    background: #ffffff;
    color: var(--gh-widget-primary-font-color);
  }

  .rdtPicker td {
    color: var(--gh-widget-light-font-color);
  }

  .gh-widget-enquire-calendar .rdtPicker td.rdtActive:hover {
    background: #242f42;
    color: var(--gh-widget-light-font-color);
  }

  .gh-widget-enquire-calendar .rdt {
    border: solid 2px var(--gh-widget-border-color);
    border-radius: var(--gh-widget-border-radius);
  }

  .gh-widget-enquire-calendar .rdtOpen {
    border: 2px solid #242f42;
  }

  table th {
    font-weight: 500 !important;
    color: var(--gh-widget-font-color);
  }

  .rdtPicker td,
  .rdtPicker th {
    text-align: center;
    height: 28px;
  }

  .gh-widget-departure-wrapper-item .rdt {
    width: fit-content;
  }

  table td span,
  .gh-widget-text-tertiary {
    color: #00b8d9;
  }

  @media (min-width: 320px) {
    .gh-widget-icon-label,
    .gh-widget-calendar-label {
      display: none;
    }
  }

  @media (min-width: 639px) {
    .gh-widget-icon-label {
      display: block;
    }

    .gh-widget-icon-label,
    .gh-widget-calendar-label {
      display: block;
    }
  }
}

.gh-widget-calendar-input {
  height: 48px;
  vertical-align: middle;
  display: inline-block;
  max-width: 100%;
  width: 100%;
  padding: 0 10px;
  background: #fff;
  color: #666;
  border: 1px solid #e5e5e5;
  transition: 0.2s ease-in-out;
  transition-property: color, background-color, border;
  font-size: var(--gh-widget-font-size-bigger);
}

.gh-widget-enquire-form-content .gh-widget-calendar-input {
  border: 0;
  padding: 0;
  text-align: center;
  color: var(--gh-widget-font-color);
  font-weight: 500;
}

.gh-widget-calendar-input {
  padding-left: 40px;
}

.gh-widget-broker-request-filters .gh-widget-calendar-input {
  padding-left: 45px;
}

.gh-widget-external-enquiry-form .gh-widget-calendar-input {
  border: 0;
  padding: 0;
  text-align: center;
  color: var(--gh-widget-primary-font-color);
  font-weight: 500;
}

.gh-widget-external-enquiry-form .gh-widget-time-input {
  transition: 0.3s ease;
  min-width: 105px;
}

.gh-widget-external-enquiry-form .gh-widget-time-input:disabled {
  opacity: 0.6;
}

.gh-widget-enquire-form-content .gh-widget-time-input {
  border-radius: var(--gh-widget-border-radius);
  border: 2px solid var(--gh-widget-border-color);
  text-align: center;
  font-weight: 500;
}

.gh-widget-old-input::placeholder {
  font-weight: 400;
  color: var(--gh-widget-font-color);
  font-size: var(--gh-widget-font-size-bigger);
}

.gh-widget-old-input {
  color: var(--gh-widget-primary-font-color);
  font-family: inherit;
  height: 48px;
  vertical-align: middle;
  display: inline-block;
  max-width: 100%;
  width: 100%;
  padding: 0 10px;
  background: #fff;
  overflow: visible;
  font-size: inherit;
}

.gh-widget-old-input:active,
.gh-widget-old-input:focus-visible {
  outline: none;
}
