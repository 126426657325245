.gh-widget-heli-card {
  &:not(:last-child) {
    padding-bottom: 10px;
  }

  &:not(:first-child) {
    padding-top: 10px;
  }
}

.gh-widget-heli-card-image .gh-widget-heli-card-title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 18px;
}

.gh-event-helicopter-desc {
  font-weight: 500;
  font-size: 15px;
  color: var(--gh-widget-ui-primary-color);
}

.gh-widget-heli-card-image .gh-widget-heli-card-title .custom-tooltip-wrapper {
  margin-left: 5px;
}

.gh-widget-heli-card-title {
  font-size: var(--gh-widget-font-size-normal);
  font-weight: 500;
  line-height: 1;
}

.gh-widget-heli-card-box {
  height: 180px;
}

/*===========Container Query===========*/
.gh-widget-app-cq.gh-widget-container-query-max-375 {
  .gh-widget-heli-card-box {
    background-size: contain !important;
  }
}
