.gh-widget-app {
  .gh-widget-back-button {
    position: relative;
    margin-bottom: 15px;
    padding: 0 0 0 20px;
    font-weight: 400;
    width: min-content;

    svg {
      content: url("../../../assets/svg/arrow-left-dark.svg");
      display: block;
      position: absolute;
      top: calc(50% - 7px);
      left: 0;

      g {
        stroke: var(--gh-widget-primary-font-color);
      }
    }
  }
}
