.gh-widget-validation-errors {
  color: #e74c3c;
  font-size: 0.75em;
  height: 18px;
  padding-top: 1px;
}

.gh-widget-validation-errors-enter-active {
  animation: animation-top-bottom 0.3s forwards;
}
.gh-widget-validation-errors-exit-active {
  animation: animation-top-bottom 0.3s backwards;
}

@keyframes animation-top-bottom {
  from {
    opacity: 0;
    transform: translateY(-10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
