.legInfoWindow {
  min-width: 320px;
  font-family: "Rubik" !important;
}

.legInfoWindow .gh-link-icon {
  margin-left: 4px !important;
}

.legInfoWindow .legInfoWindow-date-range {
  margin-top: 11px;
  color: #6d7480;
  font-size: 14px;
}

.legInfoWindow.selected .top-head {
  margin-bottom: 0;
  line-height: 1;
  font-weight: 400;
}

.legInfoWindow.selected .bottom-head {
  line-height: 1;
  margin-top: 17px;
}

.legInfoWindow .top-subtitle {
  font-size: 11px;
  text-transform: uppercase;
  font-weight: 500;
  margin-bottom: 8px;
  color: #6d7481;
  letter-spacing: 1px;
}

.legInfoWindow .legInfoWindowActionLegs {
  margin-top: 24px;
  margin-bottom: 5px;
  line-height: 1;
}

.legInfoWindow.google .legInfoWindowActionLegs {
  margin-top: 20px;
}

.legInfoWindow.event .legInfoWindowActionLegs {
  margin-top: 24px;
}

.legInfoWindow .bottom-head.secondary {
  margin-top: 0;
  line-height: 1;
}

.legInfoWindow .item-icon {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.legInfoWindow .item-icon .text {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.legInfoWindow a {
  cursor: pointer;
}

.legInfoWindow .pin-info-grid {
  display: grid;
  grid-template-columns: 50% 50%;
  /*grid-template-rows: 1fr 1fr;*/
  gap: 10px;
}

.gm-ui-hover-effect {
  outline: none !important;
  top: 0 !important;
  right: 0 !important;
}

.gm-style-iw.gm-style-iw-c {
  outline: none !important;
}

.gm-style-iw-d,
.gm-style-iw.gm-style-iw-c {
  max-height: unset !important;
}
