#getheli-widget-base-element,
#getheli-iframe-base-element,
.gh-widget-modal-root-element{
  /*===========only config gets from back===========*/
  //font
  --gh-widget-font-family: "Rubik", sans-serif;

  //font size
  --gh-widget-form-font-size: 16px;

  //colors
  --gh-widget-primary-font-color: #212121;
  --gh-widget-secondary-font-color: #6d7481;
  --gh-widget-light-font-color: #fff;

  //ui colors
  --gh-widget-ui-primary-color: #505e62;
  --gh-widget-ui-secondary-color: #D6D6D5;

  //form background color / image
  --gh-widget-form-bg: transparent;
  --gh-widget-form-bg-blur: 0px;

  //  widget button config
  --gh-widget-button-font-color: #fff;
  --gh-widget-button-bg-color: #333;
  --gh-widget-button-pulse-count: 2;
  --gh-widget-button-pulse-color: #E7F2EF;

  /*===========Local variables===========*/

  --gh-widget-font-size-bigger: 1em;
  --gh-widget-font-size-big: 0.9375em;
  --gh-widget-font-size-normal: 0.875em;
  --gh-widget-font-size-small: 0.8125em;
  --gh-widget-font-size-smaller: 0.75em;

  --gh-widget-font-color: var(--gh-widget-primary-font-color);
  --gh-widget-dark-font-color: var(--gh-widget-primary-font-color);

  //borders
  --gh-widget-border-radius: 4px;
  --gh-widget-border-color: var(--gh-widget-ui-secondary-color);

  --gh-widget-button-pulse: var(--gh-widget-button-pulse-color);
  --gh-widget-button-pulse-light: var(--gh-widget-button-pulse-color);

  //override toast vars
  --toastify-z-index: 199999 !important;

  /*===========Styles===========*/
  font-size: var(--gh-widget-form-font-size);
}
