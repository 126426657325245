.gh-widget-number-increment {
  &-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
  }

  &-label {
  }

  &-buttons {
    display: flex;
  }

  &-btn {
    background: transparent;
  }

  &-btn-increment {
  }

  &-btn-decrement {
  }

  &-svg {
    fill: var(--gh-widget-ui-primary-color) !important;
  }
}

.gh-widget-app .gh-widget-number-increment-readonly-input {
  width: 50px;
  height: 48px;
  display: flex;

  border: solid 2px var(--gh-widget-border-color);
  border-radius: var(--gh-widget-border-radius);
  font-size: 18px;
  font-weight: 500;
  color: var(--gh-widget-font-color);
  padding: 4px;
  margin: 0 5px;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Old versions of Firefox */
  user-select: none;
  pointer-events: none;
  touch-action: none;

  //align inner text
  text-align: center;

  &::-webkit-inner-spin-button {
    display: none;
  }
}
