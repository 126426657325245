.gh-widget-app {
  table {
    td,
    th {
      border: none;
    }
  }
}

//https://nuthurstgrange.com/book-a-helicopter/
#primary.ghwc-primary {
  max-width: 915px !important;
}
