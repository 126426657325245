.ReactModal__Content--after-open {
  //-ms-overflow-style: none; /* for Internet Explorer, Edge */
  //scrollbar-width: none; /* for Firefox */
  //overflow-y: auto;
  //
  //&::-webkit-scrollbar {
  //  display: none; /* for Chrome, Safari, and Opera */
  //}
}

.gh-widget-modal-enquiry-form-background {
  background-color: var(--gh-widget-form-bg);
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}

/*===========Container Query===========*/
.gh-widget-app-cq.gh-widget-container-query-max-900 {
  &.gh-widget-modal-enquiry-form-background {
    background: #fff;
    padding: 0.75em;
  }
}
.gh-widget-app-cq.gh-widget-container-query-max-375 {
  &.gh-widget-modal-enquiry-form-background {
    background: #fff;
    padding: 0.3em;
  }
}
