.gh-widget-app {
  .gh-widget-events-title-main {
    margin-bottom: 0.3em;
  }

  .gh-widget-events-title-location {
    margin-bottom: 1.5em;
  }

  .gh-widget-event-content-container {
    background-color: #f2f4f7;
    display: flex;
    flex-direction: column;
    justify-content: center;
    max-width: 100%;
    width: 100%;
  }

  .gh-widget-content-layout-wrapper {
    width: 100%;
    height: 100%;
    background: #fff;
    min-height: 730px;
  }

  .gh-widget-event-back-btn {
    margin-top: -33px;
  }

  .gh-widget-column-layout-event-image-wrapper-changed-place {
    display: none;
  }
}

/*===========Container Query===========*/
.gh-widget-app-cq.gh-widget-container-query-max-900 {
  .gh-widget-event-back-btn {
    margin-top: 0;
  }

  .gh-widget-event-content-container {
    background-color: #fff;
  }

  .gh-widget-events-title-location {
    margin-bottom: 1em;
  }

  .gh-widget-column-layout-event-image-wrapper {
    display: none;
  }

  .gh-on-demand-layout .gh-widget-column-layout-event-image-wrapper {
    display: block;
    border-radius: 4px 4px 0 0;
  }

  .gh-on-demand-layout.--enquiry-form-step .gh-widget-column-layout-event-image-wrapper {
    display: none;
  }

  .gh-widget-column-layout-event-image-wrapper-changed-place {
    display: block;
    width: 100%;
    max-width: initial;
  }

  .gh-widget-content-layout-wrapper {
    border: 1px solid var(--gh-widget-ui-secondary-color);
    border-radius: 4px;
  }
}
