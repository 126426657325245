.gh-widget-app {
  .box {
    width: 100%;
    height: 90vh;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    align-content: center;
  }

  .checkbox-bipolar-input {
    display: none;

    &:checked + label .checkbox-bipolar {
      &:after {
        left: 18px;
        right: 2px;
      }

      .on {
        opacity: 0.5;
      }

      .off {
        opacity: 0;
      }
    }
  }

  .checkbox-bipolar {
    margin-right: 15px;
    width: 36px;
    height: 20px;
    border-radius: 10px;
    border: 1px solid var(--gh-widget-light-font-color);
    display: inline-block;
    position: relative;

    &:after {
      content: '';
      display: block;
      position: absolute;
      background: var(--gh-widget-light-font-color);
      left: 2px;
      top: 2px;
      bottom: 2px;
      right: 18px;
      border-radius: 8px;
      transition: all 0.3s;
    }

    .on {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: 6px;
      opacity: 0;
      transition: 0.3s;
      font-family: sans-serif;
      font-size: 14px;
    }

    .off {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: 5px;
      opacity: 0.5;
      font-family: sans-serif;
      transition: 0.3s;
      font-size: 14px;
    }
  }

  .checkbox-input {
    display: none;
    &:checked + label .checkbox {
      &:after {
        transform: translate(-50%, -50%) scale(1);
      }
    }
  }

  .checkbox {
    border: 1px solid var(--gh-widget-light-font-color);
    border-radius: 3px;
    width: 16px;
    height: 16px;
    display: inline-block;
    position: relative;

    &:after {
      content: '';
      display: block;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%) scale(0);
      width: 12px;
      height: 12px;
      background-color: var(--gh-widget-light-font-color);
      border-radius: 3px;
      transition: 0.3s;
    }
  }

}
