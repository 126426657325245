.warning-row {
  display: flex;
  padding: 5px 0;
  align-items: flex-start;

  &__icon {
    min-width: 20px;
    margin-right: 5px;
  }
  &__message {
    font-size: 13px;
    color: #faa05a;
    margin-top: 3px;
  }
}