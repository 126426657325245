.gh-widget-main-button-component {
  font-family: inherit;
  font-size: var(--gh-widget-font-size-bigger);
  font-style: normal;
  font-weight: 400;
  text-align: center;
  padding: 16px 30px;
  border-radius: var(--gh-widget-border-radius);
  color: var(--gh-widget-light-font-color);
  cursor: pointer;
  border: none;
  min-width: 170px;
  transition: opacity 0.26s;
  text-decoration: none;
}

.gh-widget-main-button-component:hover {
  opacity: 0.8;
}

.gh-widget-main-button-component.disabled {
  cursor: not-allowed;
}

/*===========Contained===========*/
.gh-widget-main-button-component-success.gh-widget-main-button-component-contained {
  &:hover,
  & {
    background: var(--gh-widget-ui-primary-color);
  }
}

.gh-widget-main-button-component-success.gh-widget-main-button-component-contained.disabled {
  filter: opacity(0.3);

  &:hover,
  & {
    color: var(--gh-widget-light-font-color);
  }
}

.gh-widget-main-button-component-danger.gh-widget-main-button-component-contained {
  &:hover,
  & {
    background: #e74c3c;
  }
}

.gh-widget-main-button-component-warning.gh-widget-main-button-component-contained {
  &:hover,
  & {
    background: #ffab00;
  }
}

.gh-widget-main-button-component-light.gh-widget-main-button-component-contained {
  background: #ffffff;
  color: var(--gh-widget-primary-font-color);
  border: 2px solid #dfe2e8;
}

/*===========Text===========*/
.gh-widget-main-button-component-text {
  font-weight: 500;
  padding: 16px 0;
  background: none;
  min-width: fit-content;
}

.gh-widget-main-button-component-success.gh-widget-main-button-component-text {
  color: #10b897;
}

.gh-widget-main-button-component-danger.gh-widget-main-button-component-text {
  color: #e74c3c;
}

.gh-widget-main-button-component-warning.gh-widget-main-button-component-text {
  color: #ffab00;
}

.gh-widget-main-button-component-dark.gh-widget-main-button-component-text {
  color: var(--gh-widget-primary-font-color);
}

/*===========Outlined===========*/

.gh-widget-main-button-component-outlined {
  font-weight: 500;
  border: 2px solid;
  background: #fff;
  padding: 14px 28px;
}

.gh-widget-main-button-component-success.gh-widget-main-button-component-outlined {
  color: var(--gh-widget-ui-primary-color);
  border-color: var(--gh-widget-ui-primary-color);
}

.gh-widget-main-button-component-danger.gh-widget-main-button-component-outlined {
  color: #e74c3c;
  border-color: #e74c3c;
}

.gh-widget-main-button-component-warning.gh-widget-main-button-component-outlined {
  color: #ffab00;
  border-color: #ffab00;
}

.gh-widget-main-button-component-dark.gh-widget-main-button-component-outlined {
  color: var(--gh-widget-primary-font-color);
  border-color: var(--gh-widget-primary-font-color);
}

.gh-widget-main-button-component-primary.gh-widget-main-button-component-outlined {
  color: #005bff;
  border-color: #005bff;
}

.gh-widget-main-button-component-primaryBorder.gh-widget-main-button-component-outlined {
  color: var(--gh-widget-primary-font-color);
  border: 2px solid #f0f0f0;
}

.gh-widget-main-button-component-light.gh-widget-main-button-component-outlined {
  color: var(--gh-widget-primary-font-color);
  border-color: #e7eaef;
}

/*===========Loader===========*/
.gh-widget-main-button-component {
  display: flex;
  align-items: center;
  justify-content: center;
}

.gh-widget-button-loader {
  svg {
    margin-left: 3px;
    width: 16px;
  }
}
