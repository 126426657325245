.gh-widget-app {
  .gh-widget-events-dynamic-content-buttons {
    max-width: min-content;
    white-space: nowrap;
    margin-bottom: 25px;
  }

  .gh-widget-events-dynamic-content-heading {
    margin-bottom: 10px;
    font-size: 1.2em;
    font-weight: 700;
  }

  .gh-widget-events-dynamic-content-text {
    font-size: 1em !important;
    font-weight: 400;
  }

  .gh-widget-event-dynamic-content-inner-wrapper {
    span, div, p,
    h1, h3, h4, h5, h6 h2:not(.gh-widget-events-dynamic-content-heading), font, span {
      font-size: 1em !important;
      font-weight: 400;
    }

    font {
      font-size: 1.3em !important;
    }
  }

  .gh-widget-events-dynamic-content-text {
    margin-bottom: 15px;
    opacity: 0.8;
  }

  .gh-widget-events-dynamic-content-heading,
  .gh-widget-event-dynamic-content p font {
    color: var(--gh-widget-ui-primary-color) !important;
  }

  .gh-widget-event-dynamic-content {
    padding: initial;
    width: initial;
    line-height: 1.5;
    margin: initial;
    box-sizing: initial;

    &.truncate {
      /**Major Properties**/
      max-height: 200px;
      -webkit-box-orient: vertical;
      display: block;
      display: -webkit-box;
      overflow: hidden !important;
      text-overflow: ellipsis;
      -webkit-line-clamp: 4;
      position: relative;

      &:after {
        content: "";
        display: block;
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        background: rgb(255, 255, 255);
        background: linear-gradient(0deg, rgba(255, 255, 255, 1) 0%, rgba(253, 187, 45, 0) 100%);
        height: 20px;
      }
    }

    p {
      padding: initial;
      margin-top: 20px;
      margin-bottom: 20px;
    }

    img {
      padding: 5px;
      margin: initial;
      max-width: 100%;
    }

    a {
      color: var(--gh-widget-ui-primary-color);
    }

    li {
      font-size: 1em;
    }

    li::marker {
      color: var(--gh-widget-ui-primary-color);
      font-size: 1em;
    }

    ul {
      margin: 20px 0;
      padding-left: 30px;
    }

    p {
      font-size: 14px;
    }

    h2 {
      color: var(--gh-widget-ui-primary-color);
      font-size: 16px;
    }

    h3 {
      color: var(--gh-widget-ui-primary-color);
      font-size: 14px;
    }

  }
}

/*===========Container Query===========*/
.gh-widget-app-cq.gh-widget-container-query-max-900 {
  .gh-dynamic-content-extent-content-btn {
    margin: 0 auto 1.375em;
    font-size: 0.875em;
    font-weight: 600;
    line-height: 2.063em;
    color: var(--gh-widget-ui-primary-color);
  }
}

.gh-widget-app-cq.gh-widget-container-query-max-900 {
  .gh-widget-event-dynamic-content {
    img {
      width: 100% !important;
      height: auto !important;
      object-fit: cover;
      margin-bottom: 0.4rem;
    }
  }

  .gh-widget-event-dynamic-content-inner-wrapper {
    height: 100%;
    max-height: 100%;
  }
}
