.gh-widget-app {
  .gh-widget-events-info-rows {
    display: flex;
    margin-bottom: 15px;
  }

  .gh-widget-app-text.gh-widget-app-text {
    margin-bottom: 0;
  }

  .gh-widget-events-info-element:first-child {
    max-width: 133px;
    min-width: 115px;
    margin-right: 10px;
    display: flex;
    flex-wrap: nowrap;
  }

  .gh-widget-events-info-element {
    &:first-child {
      opacity: 0.8;
    }
  }
}

/*===========Container Query===========*/
.gh-widget-app-cq.gh-widget-container-query-max-900 {
  .gh-widget-events-info-rows {
    flex-direction: column;
  }
}
