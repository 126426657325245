.gh-widget-app .gh-widget-button-enquiry {
  &:hover,
  & {
    background: var(--gh-widget-button-bg-color);
  }

  color: var(--gh-widget-button-font-color);
  padding: 12px 27px 20px;
  font-family: inherit;
  font-weight: 500;
  letter-spacing: 1px;
  border: none;
  border-radius: var(--gh-widget-border-radius);
  white-space: nowrap;
  cursor: pointer;

  &-wrapper {
    z-index: 1000;
    position: fixed;
    right: -40px;
    top: 50%;
    width: 100px;
    transform: rotate(-90deg);
    transition: .2s transform ease-out;

    &:hover {
      transform: rotate(-90deg) translateY(-6px);
    }
  }
}

@keyframes gh-widget-shadow-pulse {
  0% {
    box-shadow: 0 0 0 0 var(--gh-widget-button-pulse-color);
  }
  100% {
    box-shadow: 0 0 0 20px var(--gh-widget-button-pulse-color);
  }
}

.pulse-animation {
  animation: gh-widget-shadow-pulse linear 1.8s var(--gh-widget-button-pulse-count) backwards;
  box-shadow: 0 0 0 0 var(--gh-widget-button-pulse-color);
}
