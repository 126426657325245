/*===========Screen overlay===========*/
.gh-widget-autocomplete-focus-background {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  //background: #000;
  opacity: 0.1;
  z-index: 999;
}

.gh-widget-autocomplete-focus-background-hide {
  opacity: 0;
}

/* Customize website's scrollbar like Mac OS
Not supports in Firefox and IE */

/* total width */
.gh-widget-app body::-webkit-scrollbar {
  width: 0;
}

body .gh-widget-app {
  font-family: var(--gh-widget-font-family);
  color: var(--gh-widget-font-color);
  font-weight: 400;
  font-size: var(--gh-widget-form-font-size);
}

.gh-widget-app *,
.gh-widget-app *:before,
.gh-widget-app *:after {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

.gh-widget-app svg {
  max-width: inherit;
  vertical-align: initial;
}

#getheli-iframe-base-element .gh-widget-app {
  background-color: var(--gh-widget-form-bg);
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}

.gh-widget-app-blur {
  backdrop-filter: blur(var(--gh-widget-form-bg-blur));
}

//old input styles correction
.gh-widget-calendar-input,
.gh-widget-textarea,
.gh-widget-old-input,
.enquire-time-icon {
  outline: none;
}

.gh-widget-textarea,
.gh-widget-old-input {
  &::placeholder {
    color: var(--gh-widget-secondary-font-color) !important;
  }
}

.grecaptcha-logo,
.grecaptcha-badge {
  visibility: hidden;
}

.grecaptcha-badge {
  width: 0 !important;
  height: 0 !important;
  right: auto !important;
  bottom: auto !important;
  left: 0 !important;
  top: 0 !important;
}

//paragraph
.gh-widget-p-gray {
  color: var(--gh-widget-primary-font-color);
  font-size: 1em;
  line-height: calc(1em * 1.625);
}

//google maps reset
.gmnoprint[role="menubar"] {
  display: none;
  width: 0;
  height: 0;
}

iframe, .gh-widget-app {
  //always show scrollbar for iframe
  ::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 7px;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: rgba(0, 0, 0, .5);
    -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, .5);
  }
}
