/*===========Old Classes===========*/

.gh-widget-external-enquiry-form_wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  //height: 100%;
  max-width: 450px;
  margin: 0 auto;
}

.gh-widget-external-enquiry-form {
  //padding: 10px;
  background-color: transparent;
  display: inline-block;
  width: 100%;
  border-radius: 2px;
  margin: 0;
}

.gh-widget-external-enquiry-form .gh-widget-form-content {
  transition: 0.3s ease;
}

.gh-widget-external-enquiry-form .gh-widget-form-content.disabled,
.gh-widget-column-layout-form-disabled {
  opacity: 0.65;
  pointer-events: none;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.gh-widget-external-enquiry-form .gh-widget-pax-column {
  margin-bottom: 0px !important;
}

.gh-widget-external-enquiry-form .gh-widget-pax-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.gh-widget-external-enquiry-form .gh-widget-input-group {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.gh-widget-external-enquiry-form .gh-widget-calendar-input {
  border: 0;
  padding: 0;
  text-align: center;
  color: var(--gh-widget-font-color);
  font-weight: 500;
}

.gh-widget-external-enquiry-form .gh-widget-time-input {
  transition: 0.3s ease;
}

.gh-widget-external-enquiry-form .gh-widget-time-input:disabled {
  opacity: 0.6;
}

.gh-widget-external-enquiry-form .gh-widget-input {
  border-color: var(--gh-widget-border-color);
}

.gh-widget-external-enquiry-form .gh-widget-input-wrapper {
  position: relative;
}

.gh-widget-external-enquiry-status {
  min-height: 480px;
  display: flex;
  align-items: center;
  justify-content: flex-start !important;
  flex-direction: column;
  text-align: center;
}

.gh-widget-external-enquiry-status .gh-widget-content__title {
  margin-bottom: 6px;
  font-size: 20px;
  font-weight: 400;
  line-height: 36px;
  color: var(--gh-widget-dark-font-color);
}

.gh-widget-external-enquiry-status .gh-widget-content__descrp {
  margin: 0;
  max-width: 280px;
  color: var(--gh-widget-font-color);
}

.gh-widget-external-enquiry-form_wrapper .gh-widget-checkmark__circle {
  stroke-dasharray: 166;
  stroke-dashoffset: 166;
  stroke-width: 2;
  stroke-miterlimit: 10;
  stroke: var(--gh-widget-ui-primary-color);
  fill: none;
  animation: stroke 0.6s cubic-bezier(0.65, 0, 0.45, 1) forwards;
  position: unset !important;
}

.gh-widget-external-enquiry-form_wrapper .gh-widget-checkmark-enq {
  width: 56px;
  height: 56px;
  border-radius: 50%;
  display: block;
  stroke-width: 2;
  stroke: #fff;
  stroke-miterlimit: 10;
  margin: 10% auto;
  box-shadow: inset 0px 0px 0px var(--gh-widget-ui-primary-color);
  animation: fillCircle 0.4s ease-in-out 0.4s forwards, scale 0.3s ease-in-out 0.9s both;
}

.gh-widget-external-enquiry-form_wrapper .gh-widget-checkmark__check {
  transform-origin: 50% 50%;
  stroke-dasharray: 48;
  stroke-dashoffset: 48;
  animation: stroke 0.3s cubic-bezier(0.65, 0, 0.45, 1) 0.8s forwards;
}

.gh-widget-switcher {
  display: flex;
  border-radius: var(--gh-widget-border-radius);
  border: 2px solid var(--gh-widget-border-color);
  padding: 4px;
  background: #fff;
}

.gh-widget-switcher-checked {
  margin-right: 2px;
  width: 19px;
}

.gh-widget-switcher button {
  height: 42px !important;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 10px 0 0 !important;
  width: calc(100% - 10px);
  min-width: 0 !important;
}

.gh-widget-switcher .gh-widget-button-default {
  border: 0;
  color: var(--gh-widget-dark-font-color);
  background: transparent;
  transition: 0.5s;

  line-height: 50px;
  text-transform: none;
  border-radius: var(--gh-widget-border-radius);
  text-align: center !important;
  margin: 0;
  vertical-align: middle;
  overflow: visible;
  font: inherit;
  font-weight: 500;
}

.gh-widget-enquire-form-content button {
  //font-weight: 500;
  height: 50px;
  cursor: pointer;
  border: 0;
  color: var(--gh-widget-light-font-color);
  font: inherit;
  font-weight: 500;
  border-radius: var(--gh-widget-border-radius);
}

.gh-widget-switcher .gh-widget-button-primary {
  background: var(--gh-widget-ui-secondary-color) !important;
}

.gh-widget-enquire-form-input-rounder {
  border-radius: var(--gh-widget-border-radius);
  border: 2px solid var(--gh-widget-border-color);
}
