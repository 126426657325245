.gh-widget-external-enquiry-form_wrapper {
  .react-tel-input .form-control {
    border: 2px solid var(--gh-widget-border-color);
    color: var(--gh-widget-primary-font-color);
    height: 48px;
    padding-right: 10px;
    padding-left: 57px;
    width: 100%;
    font-size: var(--gh-widget-font-size-bigger);
  }

  .react-tel-input .flag-dropdown {
    border: 2px solid var(--gh-widget-border-color);
    height: 48px;
  }

  .react-tel-input .selected-flag {
    width: 45px;
  }

  .react-tel-input .selected-flag .arrow {
    left: 23px;
    margin-left: 0;
  }

  .react-tel-input .form-control:disabled {
    background: transparent;
  }
}
