.gh-widget-app {
  .gh-widget-column-layout-first-column {
    display: flex;
    flex: 1;
    flex-direction: column;
    padding: 20px 18px;
  }

  .gh-widget-column-layout-second-column {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    max-width: 350px;
    min-width: 310px;
    border-left: 1px solid var(--gh-widget-ui-secondary-color);
  }

  .gh-widget-column-layout-event-image-wrapper {
    position: relative;
    overflow: hidden;
    height: 230px;
    max-width: 450px;
    width: 100%;
  }

  .gh-widget-column-layout-event-image {
    object-fit: cover;
    transform: translate(0px, -1px);
    border-radius: 0 4px 0 0;
    width: calc(100% + 1px);
    height: 100%;
  }

  .gh-widget-column-layout-enquiry {
    padding: 0;
    margin: 26px 18px 31px 18px;
    width: initial;
    margin-top: 5px !important;

    .gh-widget-powered-by-getheli-wrapper {
      display: none;
    }
  }

  .gh-widget-enquiry-form-title {
    display: none;
  }

  .gh-widget-card-featured-tag.gh-widget-card-featured-tag-inside-event {
    top: 28px;
    right: 26px;
  }

  .gh-widget-card-event-tag {
    left: 26px;
    top: 28px;
  }

  .gh-widget-column-layout-wrapper {
    max-height: 100%;
    height: 100%;
    border: 1px solid var(--gh-widget-ui-secondary-color);
    border-radius: 4px;
    width: 100%;
  }

  .gh-widget-column-layout-helicopter-options-step {
    max-height: 923px;
    justify-content: flex-start;
    min-height: 800px;
    &.--options {
      overflow-y: scroll;
    }
  }
}

/*===========Container Query===========*/
.gh-widget-app-cq.gh-widget-container-query-max-900 {
  .gh-widget-column-layout-wrapper {
    flex-direction: column;
    padding: 1em;
  }

  .gh-on-demand-layout .gh-widget-column-layout-first-column {
    display: none;
  }

  .gh-on-demand-layout.--enquiry-form-step .gh-widget-column-layout-first-column {
    display: initial;
    padding: 1rem;
  }

  .gh-widget-column-layout-second-column {
    width: 100%;
    max-width: 450px;
    min-width: 300px;
    border: none;
    align-items: center;
  }

  .gh-widget-column-layout-enquiry {
    margin: 0;
    background-color: #fff;
    //padding-top: 12px;
    width: 100%;
    padding-top: 0;
  }

  .gh-widget-column-layout-second-column {
    align-items: center;
  }

  .gh-widget-column-layout-first-column {
    padding: 0;
    max-width: 450px;
    width: 100%;
  }

  .gh-widget-enquiry-form-title {
    width: 100%;
    margin-bottom: 0.6em;
    font-size: 1.5em;
    font-weight: 400;
    line-height: 24px;
    display: block;
  }

  .gh-widget-card-featured-tag.gh-widget-card-featured-tag-inside-event {
    top: 20px;
    right: 20px;
  }

  .gh-widget-card-event-tag {
    left: 20px;
    top: 20px;
  }

  //  widget type modal
  &.gh-widget-app-type-modal {
    .gh-widget-column-layout-wrapper {
      //padding: 0;
    }

    .gh-widget-events-wrapper .gh-widget-column-layout-wrapper {
      border: none;
    }
  }

  .gh-widget-column-layout-event-image {
    border-radius: initial;
  }

  .gh-widget-column-layout-wrapper {
    border: none;
  }

  // mobile except modal layout
  &:not(&.gh-widget-app-type-modal) {
    .gh-on-demand-layout {
      border: 1px solid var(--gh-widget-ui-secondary-color);
    }
  }

  .gh-widget-column-layout-helicopter-options-step {
    max-height: initial;
    overflow-y: initial;
    justify-content: initial;
  }

  .gh-on-demand-layout.--enquiry-form-step {
    .gh-widget-column-layout-first-column {
      padding-bottom: 0;
    }
    .on-demand-map-wrapper {
      margin-bottom: 0;
    }
  }
}

.gh-widget-app-cq.gh-widget-container-query-max-375 {
  .gh-widget-column-layout-second-column {
    min-width: initial;
  }

  .gh-widget-enquire-calendar .rdtOpen .rdtPicker {
    width: 200px;
  }

  .gh-widget-column-layout-event-image-wrapper {
    height: auto;
  }

  .gh-widget-column-layout-wrapper {
    padding: 10px;
  }
}

/*===========Only IOS===========*/
@supports (-webkit-touch-callout: none) {
  .gh-widget-app-cq.gh-widget-container-query-max-375 {
    .gh-widget-column-layout-event-image-wrapper {
      height: 175px;
    }
  }
}
