@import "../../../assets/styles/utils";

.gh-widget-app {
  .gm-style .gm-style-iw-c {
    padding: 0 !important;
  }

  .gm-style .gm-style-iw {
    font-size: max(11px, remFromConfig(0.81));
  }


  .gm-style-iw-d {
    scrollbar-width: none;
    overflow: hidden !important;

    &::-webkit-scrollbar {
      width: 0;
      height: 0;
    }
  }

  .legInfoWindow {
    min-width: 320px;
    font-family: "Rubik" !important;
  }

  .legInfoWindow .gh-link-icon {
    margin-left: 4px !important;
  }

  .legInfoWindow .legInfoWindow-date-range {
    margin-top: 11px;
    color: #6d7480;
    font-size: remFromConfig(0.87);
  }

  .legInfoWindow.selected .top-head {
    margin-bottom: 0;
    line-height: 1;
    font-weight: 400;
  }

  .legInfoWindow.selected .bottom-head {
    line-height: 1;
    margin-top: 17px;
  }

  .legInfoWindow .top-subtitle {
    font-size: remFromConfig(0.69);
    text-transform: uppercase;
    font-weight: 500;
    margin-bottom: 8px;
    color: #6d7481;
    letter-spacing: 1px;
  }

  .legInfoWindow .legInfoWindowActionLegs {
    margin-top: 24px;
    margin-bottom: 5px;
    line-height: 1;
  }

  .legInfoWindow.google .legInfoWindowActionLegs {
    margin-top: 20px;
  }

  .legInfoWindow.event .legInfoWindowActionLegs {
    margin-top: 24px;
  }

  .legInfoWindow .bottom-head.secondary {
    margin-top: 0;
    line-height: 1;
  }

  .legInfoWindow .item-icon.type .text {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  .legInfoWindow {
    max-width: 320px;
    user-select: none;
  }

  .legInfoWindow .legInfoWindowActionLegs {
    display: flex;
  }

  .legInfoWindow .legInfoWindowActionLegs a {
    font-size: remFromConfig(0.87);
    font-weight: 500;
    font-family: "rubik", Arial, Helvetica, sans-serif;
    color: var(--gh-widget-ui-primary-color);
  }

  .legInfoWindow .legInfoWindowActionLegs a:hover {
    filter: opacity(0.8);
  }

  .legInfoWindow .imageBox {
    width: 320px;
    height: 160px;
    overflow: hidden;
    max-width: 320px;
  }

  .legInfoWindow .content {
    padding: 15px;
  }

  .legInfoWindow .content h4 {
    font-size: remFromConfig(1);
    color: var(--gh-widget-ui-primary-color);
    font-weight: 500;
    font-family: "rubik", Arial, Helvetica, sans-serif;
    margin: 0;
  }

  .legInfoWindow .content a {
    text-decoration: none;
    outline: none;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    flex: 1;
  }

  .legInfoWindow .content .item-icon {
    display: flex;
    align-content: center;
  }

  .legInfoWindow .content .item-icon img {
    max-width: 14px;
  }

  .legInfoWindow .content .item-icon .text {
    padding-left: 8px;
  }

  .legInfoWindow .content .top-head {
    margin-bottom: 15px;
    display: flex;
    line-height: 1;
  }

  .legInfoWindow .content .bottom-head {
    display: flex;
    margin-bottom: 0;
  }

  .legInfoWindow .content .bottom-head svg {
    margin-left: 12px;

    path {
      stroke: var(--gh-widget-ui-primary-color);
    }
  }


  .gh-widget-event-card-link {
    color: var(--gh-widget-ui-primary-color);
    font-size: remFromConfig(1);

    g {
      stroke: var(--gh-widget-ui-primary-color);
    }
  }
}

.gh-widget-app-cq.gh-widget-container-query-max-540 {
  .legInfoWindow{
    min-width: initial;
    max-width: 100% ;

    .imageBox{
      max-width: 100%;
    }
  }
}
