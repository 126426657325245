.gh-widget-app {
  .gh-widget-main-wrapper {
    background: none;
    display: flex;
    align-items: center;
    flex-direction: column;
    padding: 25px;
    margin-bottom: 15px;
    position: relative;
    min-height: 75vh;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
  }

  .gh-widget-main-wrapper.gh-getheli-platform {
    min-height: fit-content;
  }

  .gh-widget-main-wrapper-small-paddings {
    padding: 25px;
    padding-top: 10px;
  }

  .gh-widget-main-tabs {
    max-width: 100%;
    padding-bottom: 10px;
  }

  .gh-widget-main-tabs .gh-widget-tabs-new-design {
    display: flex;
    justify-content: center;
  }

  .gh-widget-complex-layout-poweredBy {
    display: flex;
    justify-content: flex-start;
    width: 100%;
  }
}

/*===========Container Query===========*/
.gh-widget-app-cq.gh-widget-container-query-max-900 {
  .gh-widget-main-wrapper {
    background-color: #fff;
    padding: 0;
    margin-bottom: 0;
  }

  .gh-widget-main-tabs {
    padding-bottom: 0;
  }

  .gh-widget-main-title {
    text-align: center;
    font-size: 1.2em;
  }

  .gh-widget-complex-layout-poweredBy {
    position: initial;
    left: initial;
    bottom: initial;
    margin: 15px 0 24px;
  }
}
