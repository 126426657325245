.gh-widget-app {
  .on-demand-wrapper {
  }

  .on-demand-widget {
    margin-bottom: 10px;
  }

  .on-demand-map-wrapper {
    height: 100%;
    margin-bottom: 0;
  }

  .gh-on-demand-layout-to-default-location .gh-widget-app-map-card-to-btn,
  .gh-on-demand-layout-from-default-location .gh-widget-app-map-card-from-btn {
    pointer-events: none;
    opacity: 0.2;
  }

}

.gh-widget-app-cq.gh-widget-container-query-max-900 {
  .gh-widget-tab-on-demand {
    .gh-widget-column-layout-wrapper {
      padding: 0;
    }

    .gh-widget-external-enquiry-form_wrapper:not(.gh-widget-external-enquiry-form-options) {
      padding: 1rem;
    }
  }

  .gh-on-demand-layout.--enquiry-form-step {
    .on-demand-map-wrapper {
      height: 356px;
    }
  }
}

.gh-widget-app-on-demand-information {
  & > p {
    line-height: 0;
    opacity: 0;
  }
}

.gh-mobile-map-legend {
  display: none;

  .map-legend {
    .map-legend-list {
      padding: 0;
    }
  }

}

.map-legend {
  display: flex;
  flex-direction: column;
  gap: 1rem;

  .map-legend-title {
    font-weight: 400;
    font-size: 16px;
    display: flex;

    .map-legend-title-label {
      padding-right: 3px;
    }
  }

  .map-legend-list {
    display: flex;
    column-gap: 1rem;
    row-gap: 0.5rem;

    .map-legend-item {
      display: flex;
      align-items: center;
      gap: 0.5rem;
      font-size: 14px;

      & > img {
        width: 25px;
      }

      & > span {
      }
    }
  }
}

.gh-widget-app-cq.gh-widget-container-query-max-900 {
  .gh-desktop-map-legend {
    display: none;
  }

  .gh-mobile-map-legend {
    display: block;

    .map-legend {
      .map-legend-title {
        margin-top: 8px;
        font-weight: 400;
        font-size: 0.9em;
      }

      .map-legend-list {
        margin-bottom: 10px;
      }
    }
  }
}

.gh-widget-app-cq.gh-widget-container-query-max-1040 {
  .map-legend {
    .map-legend-title {
      margin-top: 8px;
    }

    .map-legend-item {
      font-size: 14px;

      img {
        width: 22px;
      }
    }

    .map-legend-list {
      display: grid;
      grid-template-columns: auto auto;
      grid-column-gap: 2rem;
      padding: 0;
    }
  }
}

