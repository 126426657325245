.gh-widget-app {
  .gh-widget-main-tab-pane {
    background: #ffffff;
    border-radius: 4px;
    margin: 5px;
    width: 100%;
  }
}

.gh-widget-app-cq.gh-widget-container-query-max-900 {
  .gh-widget-main-tab-pane {
    margin: 0;
    border: none;
  }
}
