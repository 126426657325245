.gh-widget-enquiry-form-warning-icon-wrapper {
  height: 20px;
}

.gh-widget-enquiry-form-swap-wrapper {
  position: relative;
  height: 10px;
}

button.gh-widget-enquiry-form-swap-button {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
  padding: 0;
  width: 24px;
  height: 24px;
  position: absolute;
  right: 0;
  top: 0;
  cursor: pointer;
  z-index: 3;
}

.gh-widget-enquiry-form-continue-btn {
  width: 100%;
}

.gh-widget-enquiry-date-and-time-picker {
  .rdtPicker {
    transform: translateY(calc(-58px - 100%));
  }
}

.gh-widget-external-enquiry-form_wrapper {
  padding-bottom: 20px;
}

.gh-widget-enquiry-buttons-wrapper {
  display: flex;
  width: 100%;
  position: sticky;
  bottom: 0;
  background: #fff;
  padding: 1rem 0;
  z-index: 1;

  button {
    display: flex;
    flex: 1;
    min-width: 140px;
    padding: 16px 18px;

    &:first-child {
      margin-right: 5px;
    }

    &:last-child {
      margin-left: 5px;
    }
  }
}

.gh-widget-enquiry-error-container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 15px !important;
  background: #f3f4f7;
  color: #242f42;

  & > span {
    margin-right: 20px;
  }
}

.gh-widget-stepper {
  display: flex;
  justify-content: space-between;
  position: relative;
  margin-bottom: 18px !important;

  &::before {
    content: "";
    position: absolute;
    display: inline-block;
    height: 1px;
    width: 100%;
    background: #979797;
    z-index: 0;
    top: 15px;
  }

  .gh-widget-stepper-step {
    display: flex;
    flex-direction: column;
    align-items: center;
    min-height: 51px;
    z-index: 1;
    background-color: #fff;
    gap: 5px;

    .gh-widget-stepper-number {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 50px;
      height: 30px;

      svg {
        g {
          stroke: var(--gh-widget-ui-primary-color);
        }
      }

      & > span {
        width: 24px;
        height: 24px;
        background-color: #f2f4f7;
        color: #242f42;
        text-align: center;
        line-height: 24px;
        border-radius: 50%;
      }

      .active-step {
        background-color: var(--gh-widget-ui-primary-color);
        color: var(--gh-widget-light-font-color);
      }
    }

    .gh-widget-stepper-step-description {
      font-size: 14px;
      color: #242f42;
      font-weight: 400;
      position: absolute;
      bottom: 0;
    }
  }
}

/*===========Animations===========*/
.gh-widget-steps-container .gh-widget-step-item {
  display: none;
}

.gh-widget-steps-container .gh-widget-step-item.active {
  display: block;
}

.gh-widget-animation-flip-exit-active {
  animation: flip 0.3s ease-out;
  transform-origin: center center;
}

@keyframes stroke {
  100% {
    stroke-dashoffset: 0;
  }
}

@keyframes scale {
  0%,
  100% {
    transform: none;
  }
  50% {
    transform: scale3d(1.1, 1.1, 1);
  }
}

@keyframes fillCircle {
  100% {
    box-shadow: inset 0px 0px 0px 30px var(--gh-widget-ui-primary-color);
  }
}

@keyframes flip {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(180deg);
  }
}

.gh-widget-autocomplete-dropdown {
  height: 400px;
  overflow-y: scroll;
}

/*===========Container Query===========*/
.gh-widget-app-cq.gh-widget-container-query-max-900 {
  .gh-widget-enquiry-buttons-wrapper {
    position: initial;
    bottom: initial;
    background: initial;
  }

  .gh-widget-steps-container .gh-widget-step-item {
    margin-top: 0.6rem;
  }

  .gh-widget-gdpr {
    margin-top: 1em;
  }
}

.gh-widget-app-cq.gh-widget-container-query-max-479 {
  .gh-widget-number-increment-readonly-input {
    font-size: 0.93em;
    height: 38.4px;
    width: 40px;
  }

  .gh-widget-number-increment-btn {
    height: initial;
  }

  .gh-widget-pax-column {
    margin-bottom: 25px;
  }

  .gh-widget-enquiry-buttons-wrapper {
    button {
      min-width: min-content;
    }
  }
}

.gh-widget-app-cq.gh-widget-container-query-max-375 {
  .gh-widget-number-increment-readonly-input {
    font-size: 0.93em;
    height: 30.72px;
    width: 32px;
  }
}
